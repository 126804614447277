import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    pAuth: false,
    pUserEmail: "",
    currentdetails: {},
    accountdetails: {},
    Orgdetails: {},
    pagecount: "",
    exportCheck:false,
    customerDailogCheck:false,
    projectDailogCheck:false,
    multipleProject:false,
    CustomerItems:{},
  },
  getters: {
    GetAuth: state => {
      return state.pAuth || false;
    },

    GetUserEmail: state => {
      return state.pUserEmail;
    },
    Getcurrentdetails: state => {
      return state.currentdetails;
    },
    Getaccountdetails: state => {
      return state.accountdetails;
    },
    GetOrgdetails: state => {
      return state.Orgdetails;
    },
    Getpagecount: state => {
      return state.pagecount;
    },
    GetexportCheck:state=>{
      return state.exportCheck;
    },
    GetCustomerDailogCheck:state=>{
      return state.customerDailogCheck;
    },
    
    GetAccountDailogCheck:state=>{
      return state.projectDailogCheck;
    },
    GetmultipleProject:state=>{
      return state.multipleProject;
    },
    GetCustomerItems:state=>{
      return state.CustomerItems;
    },
  },
  mutations: {
    SetAuth(state, status) {
      state.pAuth = status;
    },
    // To set current user email
    SetUserEmail(state, useremail) {
      state.pUserEmail = useremail;
    },
    Setcurrentdetails(state, currentdetails) {
      state.currentdetails = currentdetails;
    },
    Setaccountdetails(state, accountdetails) {
      state.accountdetails = accountdetails;
    },
    SetOrgdetails(state, Orgdetails) {
      state.Orgdetails = Orgdetails;
    },
    Setpagecount(state, pagecount) {
      state.pagecount = pagecount
    },
    SetexportCheck(state,exportCheck){
      state.exportCheck=exportCheck;
    },
    SetCustomerDailogCheck(state,customerDailogCheck){
      state.customerDailogCheck=customerDailogCheck;
    },
    SetAccountDailogCheck(state,projectDailogCheck){
      state.projectDailogCheck=projectDailogCheck;
    },
    SetmultipleProject(state,multipleProject){
      state.multipleProject=multipleProject;
    },
    SetCustomerItems(state,CustomerItems){
      state.CustomerItems=CustomerItems;
    },
 

    
  },
  modules: {
  }
})

export default store
