<template>
  <v-app id="inspire">
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <div style="height: 100vh; width: 100%" class="maindiv cardCss">
      <v-container class="fill-height" fluid>
        <v-row justify="center" align="center">
          <v-col cols="12" md="4" lg="4">
            <v-card
              class="d-flex responsive-card maindiv rounded-xl mt-n16"
              height="400"
              :class="{ 'lg-size': $vuetify.breakpoint.lgAndUp }"
            >
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-center">
                    <v-img
                      max-width="180"
                      max-height="100"
                      src="@/assets/CollectWRK2.jpg"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="green"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                  <v-window v-model="steps" touchless>
                    <v-window-item :value="1">
                      <v-card-text>
                        <v-form ref="form">
                          <div v-if="otpVisible == 0">
                            <v-text-field
                              dense
                              outlined
                              label="Email"
                              v-model="userEmail"
                              :rules="[rules.required, rules.email]"
                              required
                              append-icon="mdi-email"
                              type="text"
                              color="black"
                              @keyup.enter="validate_sign_in('otp')"
                              style="font-size: 13px; color: black"
                            >
                            </v-text-field>
                          </div>
                          <div v-if="otpVisible == 1">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              label="Email"
                              v-model="userEmail"
                              :rules="[rules.required, rules.email]"
                              required
                              append-icon="mdi-email"
                              type="text"
                              color="black"
                              style="font-size: 13px; color: black"
                            >
                            </v-text-field>
                          </div>
                          <div v-show="otpVisible == 1">
                            <div class="text-left font-weight-medium">
                              Please enter the OTP sent to your email
                            </div>
                            <v-row no-gutters class="mt-2">
                              <v-otp-input
                                v-model="otpInput"
                                :ref="otpRef"
                                length="6"
                              ></v-otp-input>
                              <div class="ml-2 font-weight-medium">
                                Resend OTP in ({{ timer }}s)
                                <v-icon
                                  v-if="!timerRunning"
                                  color="#4747fe"
                                  class="mb-2 ml-2 mt-1"
                                  @click="restartTimer('otp')"
                                  >mdi-refresh</v-icon
                                >
                              </div>
                            </v-row>
                          </div>
                          <div v-if="otpVisible == 0">
                            <v-btn
                              block
                              dark
                              class="mt-4 elevation-0 text-capitalize button"
                              :loading="loading1"
                              @click="validate_sign_in('otp')"
                              >Send OTP</v-btn
                            >
                          </div>
                          <div v-if="otpVisible == 1">
                            <v-btn
                              block
                              dark
                              class="mt-4 text-capitalize button"
                              :loading="loading1"
                              @click="validate_sign_in('login')"
                              >Login</v-btn
                            >
                          </div>
                        </v-form>
                      </v-card-text>
                    </v-window-item>
                  </v-window>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div
        style="position: absolute; bottom: 0; right: 0; padding: 10px"
        class="white--text"
      >
        V-2.0.3
      </div>
    </div>
  </v-app>
</template>
  <script>
/* eslint-disable */
import SnackBar from "@/components/SnackBar.vue";
import { Auth } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";
import { GetOrgDetials } from "@/graphql/queries.js";
import { get_details_Org } from "@/mixins/GetOrgdetails";
export default {
  mixins: [get_details_Org],
  components: {
    SnackBar,
  },
  data() {
    return {
      userEmail: "",
      SnackBarComponent: {},

      steps: null,
      loading1: false,

      loading: false,
      otpInput: "",
      otpVisible: 0,
      userDetails: {},
      isEmailEntered: false,
      timerRunning: false,
      timer: 120,
      otpRef: "otpField",
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid Email";
        },
      },
    };
  },
  created() {
    window.history.forward();
    this.$store.commit("SetUserEmail", "");
    this.$store.commit("SetOrgdetails", {});
    if (this.$router.history.current.params.otp == 1) {
      this.otpVisible = 1;
      this.userEmail = this.$router.history.current.params.email;
      this.send_otp();
    }

    // this.$root.$on("sign_up", this.sign_up);
  },
  methods: {
    goToHome() {
      window.location.href = "https://www.collectwrk.com/";
    },
    async get_org_details() {
      try {
        let result = await API.graphql(
          graphqlOperation(GetOrgDetials, {
            input: {
              email_id: this.$store.getters.GetUserEmail,
            },
          })
        );

        var ressucess = JSON.parse(result.data.GetOrgDetials);
        if (ressucess.status == "success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            SnackbarText: "Login Successfully!!",
            timeout: 5000,
            Top: true,
          };
          this.loading1 = false;

          // this.$router.push("/navbar");
          var response = JSON.parse(result.data.GetOrgDetials).response[0];

          if (response.status == 1) {
            this.$store.commit("Setcurrentdetails", response);

            if (this.$store.getters.Getcurrentdetails.role_id == "3") {
              this.$router.push("/AccountList");
            } else if (this.$store.getters.Getcurrentdetails.role_id == "4") {
              this.$router.push("/CollectorList");
            } else {
              this.$router.push("/DashBoard");
              this.get_details_Org();
            }
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Your Account has been Deactivated",
            };
            this.userEmail = "";
            this.otpVisible = 0;
            this.otpInput = "";
            this.$refs.form.resetValidation();
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: ressucess.message,
          };
          this.otpInput = "";
          this.otpVisible = 0;
          this.userEmail = "";
          this.loading1 = false;
        }
      } catch (error) {
        console.log(error);
        this.otpVisible = 0;
        this.userEmail = "";
        this.otpInput = "";
        this.loading1 = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    startTimer() {
      this.timerRunning = true;
      this.timer = 120;
      this.countdown();
    },
    restartTimer() {
      this.validate_sign_in("otp");
      this.timer = 120;
      this.countdown2();

      this.timerRunning = true;
    },
    countdown() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer--;
          this.countdown();
        }, 1000);
      } else {
        this.timerRunning = false;
      }
    },
    countdown2() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer--;
          this.countdown2();
        }, 3000);
      } else {
        this.timerRunning = false;
      }
    },
    async send_otp() {
      try {
        const user = await Auth.signIn(this.userEmail)

          .then((user) => {
            this.userDetails = user;
          })
          .catch((err) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Couldn't find your Stichh account",
              timeout: 5000,
              Top: true,
            };
          });
      } catch (error) {}
    },
    validate_sign_in(val) {
      // this.$router.push("/navbar");
      var valid = true;

      if (this.userEmail == "") {
        valid = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Email  Is Required",
          timeout: 5000,
          Top: true,
        };
      }
      if (valid) {
        if (val == "otp") {
          this.startTimer();
          this.generate_otp();
        } else {
          this.login_with_otp();
        }
      }
    },
    focusOnOTPField() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[this.otpRef].focus();
        }, 100);
      });
    },

    async generate_otp() {
      this.loading1 = true;
      try {
        const user = await Auth.signIn(this.userEmail.toLowerCase())
          .then((user) => {
            this.userDetails = user;

            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: "OTP Sent Successfully",
              timeout: 5000,
              Top: true,
            };
            this.focusOnOTPField();
            this.otpVisible = 1;

            this.loading1 = false;
          })
          .catch((err) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Couldn't find your  account!!",
              timeout: 5000,
              Top: true,
            };
            this.loading1 = false;
          });
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.message,
          timeout: 5000,
          Top: true,
        };
        this.loading1 = false;
      }
    },

    async login_with_otp() {
      this.loading1 = true;
      try {
        await Auth.sendCustomChallengeAnswer(this.userDetails, this.otpInput);
        await Auth.currentAuthenticatedUser()
          .then((user) => {
            this.$store.commit("SetAuth", true);
            this.$store.commit("SetUserEmail", this.userEmail.toLowerCase());

            this.get_org_details();
            // this.$router.push("/navbar");
          })
          .catch((err) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Invalid OTP",
              timeout: 5000,
              Top: true,
            };
            this.loading1 = false;
            this.otpInput = "";
            // this.$refs.otp.focus();
          });
      } catch (error) {
       
        if (
          error.message ==
          "Invalid version in Lambda response. Version should be 1"
        ) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Invalid OTP",
            timeout: 5000,
            Top: true,
          };
          this.otpInput = "";
        } else if (error.message == "Invalid session for the user.") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText:
              "Multiple Attempts, Please Resend OTP and Login again",
            timeout: 5000,
            Top: true,
          };
          this.otpInput = "";
        } else if (error.message == "Challenge response cannot be empty") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Enter the OTP",
            timeout: 5000,
            Top: true,
          };
          this.otpInput = "";
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: error.message,
            timeout: 5000,
            Top: true,
          };
        }
        this.otpInput = "";
        this.loading1 = false;
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.test_cursor input {
  cursor: pointer;
}

.lg-size {
  max-width: 500px;
  margin-top: 150%;
  margin: 0 auto;
}
.team-img {
  width: 50%;
}
</style>