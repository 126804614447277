import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../views/LoginPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/navbar',
    name: 'navbar',
    component: () => import("../components/NavBar.vue"),
    props: true,
    children: [
      {
        path: '/DashBoard',
        name: 'DashBoard',
        component: () => import("../components/Summary/OrgSummary.vue"),
      },
      {
        path: '/AccountList',
        name: 'AccountList',
        component: () => import("../components/AccountList.vue"),
      },

      {
        path: "/AccountView",
        name: "AccountView",
        component: () => import("@/components/AccountView.vue")
      },
      {
        path: "/OrgAgents",
        name: "OrgAgents",
        component: () => import("@/components/UserList.vue")
      },
      {
        path: "/SettingList",
        name: "SettingList",
        component: () => import("@/components/Settings/OrgDetailsList.vue")

      },
      {
        path: "/AduitLog",
        name: 'AduitLog',
        component: () => import("@/components/AduitFiles/AduitType.vue")
      },

      {
        path: "/OrgMailSetting",
        name: 'OrgEmailconfiguration',
        component: () => import("@/components/Settings/OrgEmailconfiguration.vue")
      },
      {
        path: "/OrgMail",
        name: 'OrgMail',
        component: () => import("@/components/Accounts/AccountSendmail.vue")
      },

      {
        path: "/CustomerList",
        name: 'CustomerList',
        component: () => import("@/components/CustomerList.vue")
      },
      {
        path: "/ApprovalList",
        name: 'ApprovalList',
        component: () => import("@/components/VerificationList.vue")
      },
      {
        path: "/BusinessRole",
        name: 'BusinessRole',
        component: () => import("@/components/Settings/BusinessLine.vue")
      },
      {
        path: "/RegionList",
        name: 'RegionList',
        component: () => import("@/components/Settings/RegionList.vue")
      },
      {
        path: "/SubscriptionFee",
        name: "SubscriptionFee",
        component: () => import("@/components/Settings/SubscriptionFee.vue")
      },
      {
        path: "/CollectorList",
        name: "CollectorList",
        component: () => import("@/components/CollectorList.vue")
      },
      {
        path: "/DispostioncodeList",
        name: "DispostioncodeList",
        component: () => import("@/components/Settings/DispostioncodeList.vue")
      },
      {
        path: "/AnalyticsList",
        name: "AnalyticsList",
        component: () => import("@/components/Dashboard/AnalyticsList.vue")
      },


    ]
  },

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
