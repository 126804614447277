/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const GetPortalUserDetailsList = /* GraphQL */ `
  query GetPortalUserDetailsList($input: GetPortalUserDetailsListInput) {
    GetPortalUserDetailsList(input: $input)
  }
`;
export const GetOrgCustomersListById = /* GraphQL */ `
  query GetOrgCustomersListById($input: GetOrgCustomersListByIdInput) {
    GetOrgCustomersListById(input: $input)
  }
`;
export const GetMasterOrgRoles = /* GraphQL */ `
  query GetMasterOrgRoles {
    GetMasterOrgRoles
  }
`;
export const GetOrgAccReceivebalesListById = /* GraphQL */ `
  query GetOrgAccReceivebalesListById(
    $input: GetOrgAccReceivebalesListByIdInput
  ) {
    GetOrgAccReceivebalesListById(input: $input)
  }
`;
export const GetOrgAccountInvoiceDueList = /* GraphQL */ `
  query GetOrgAccountInvoiceDueList($input: GetOrgAccountInvoiceDueListInput) {
    GetOrgAccountInvoiceDueList(input: $input)
  }
`;
export const GetOrgAccountInvoicePendingList = /* GraphQL */ `
  query GetOrgAccountInvoicePendingList(
    $input: GetOrgAccountInvoicePendingListInput
  ) {
    GetOrgAccountInvoicePendingList(input: $input)
  }
`;
export const GetOrgAccountInvoicePaidList = /* GraphQL */ `
  query GetOrgAccountInvoicePaidList(
    $input: GetOrgAccountInvoicePaidListInput
  ) {
    GetOrgAccountInvoicePaidList(input: $input)
  }
`;
export const GetOrgDetials = /* GraphQL */ `
  query GetOrgDetials($input: GetOrgDetialsInput) {
    GetOrgDetials(input: $input)
  }
`;
export const GetOrgDetialsByID = /* GraphQL */ `
  query GetOrgDetialsByID($input: GetOrgDetialsByIDInput) {
    GetOrgDetialsByID(input: $input)
  }
`;
export const GetOrgPortalUserList = /* GraphQL */ `
  query GetOrgPortalUserList($input: GetOrgPortalUserListInput) {
    GetOrgPortalUserList(input: $input)
  }
`;
export const GetActionLogsByOrgID = /* GraphQL */ `
  query GetActionLogsByOrgID($input: GetActionLogsByOrgIDInput) {
    GetActionLogsByOrgID(input: $input)
  }
`;
export const GetEmailActionLogsByOrgID = /* GraphQL */ `
  query GetEmailActionLogsByOrgID($input: GetEmailActionLogsByOrgIDInput) {
    GetEmailActionLogsByOrgID(input: $input)
  }
`;
export const GetWhtasappActionLogsByOrgID = /* GraphQL */ `
  query GetWhtasappActionLogsByOrgID(
    $input: GetWhtasappActionLogsByOrgIDInput
  ) {
    GetWhtasappActionLogsByOrgID(input: $input)
  }
`;
export const GetMasterPortalDateFormats = /* GraphQL */ `
  query GetMasterPortalDateFormats {
    GetMasterPortalDateFormats
  }
`;
export const GetOrgSummary = /* GraphQL */ `
  query GetOrgSummary($input: GetOrgSummaryInput) {
    GetOrgSummary(input: $input)
  }
`;
export const GetInvoiceDueList = /* GraphQL */ `
  query GetInvoiceDueList($input: GetInvoiceDueListInput) {
    GetInvoiceDueList(input: $input)
  }
`;
export const GetInvoicePendingDueList = /* GraphQL */ `
  query GetInvoicePendingDueList($input: GetInvoicePendingDueListInput) {
    GetInvoicePendingDueList(input: $input)
  }
`;
export const GetOrgAccountDetailsByID = /* GraphQL */ `
  query GetOrgAccountDetailsByID($input: GetOrgAccountDetailsByIDInput) {
    GetOrgAccountDetailsByID(input: $input)
  }
`;
export const GetOrgAccountContactDetailsByID = /* GraphQL */ `
  query GetOrgAccountContactDetailsByID(
    $input: GetOrgAccountContactDetailsByIDInput
  ) {
    GetOrgAccountContactDetailsByID(input: $input)
  }
`;
export const GetOrgPortalUsersList = /* GraphQL */ `
  query GetOrgPortalUsersList($input: GetOrgPortalUsersListInput) {
    GetOrgPortalUsersList(input: $input)
  }
`;
export const GetOrgVerifiedUsers = /* GraphQL */ `
  query GetOrgVerifiedUsers($input: GetOrgVerifiedUsersInput) {
    GetOrgVerifiedUsers(input: $input)
  }
`;
export const GetOrgBusinessLines = /* GraphQL */ `
  query GetOrgBusinessLines($input: GetOrgBusinessLinesInput) {
    GetOrgBusinessLines(input: $input)
  }
`;
export const GetOrgRegionLists = /* GraphQL */ `
  query GetOrgRegionLists($input: GetOrgRegionListsInput) {
    GetOrgRegionLists(input: $input)
  }
`;
export const GetBMSOrgEmailTemplateList = /* GraphQL */ `
  query GetBMSOrgEmailTemplateList($input: GetBMSOrgEmailTemplateListInput) {
    GetBMSOrgEmailTemplateList(input: $input)
  }
`;
export const GetOrgAccEmailTemplateList = /* GraphQL */ `
  query GetOrgAccEmailTemplateList($input: GetOrgAccEmailTemplateListInput) {
    GetOrgAccEmailTemplateList(input: $input)
  }
`;
export const GetOrgAccVerifiedUsers = /* GraphQL */ `
  query GetOrgAccVerifiedUsers($input: GetOrgAccVerifiedUsersInput) {
    GetOrgAccVerifiedUsers(input: $input)
  }
`;
export const GetOrgAccPortalUsersList = /* GraphQL */ `
  query GetOrgAccPortalUsersList($input: GetOrgAccPortalUsersListInput) {
    GetOrgAccPortalUsersList(input: $input)
  }
`;
export const GetPortalPlaceHolderList = /* GraphQL */ `
  query GetPortalPlaceHolderList {
    GetPortalPlaceHolderList
  }
`;
export const GetOrgAccSummary = /* GraphQL */ `
  query GetOrgAccSummary($input: GetOrgAccSummaryInput) {
    GetOrgAccSummary(input: $input)
  }
`;
export const GetOrgAccAPIIntegrationDetails = /* GraphQL */ `
  query GetOrgAccAPIIntegrationDetails(
    $input: GetOrgAccAPIIntegrationDetailsInput
  ) {
    GetOrgAccAPIIntegrationDetails(input: $input)
  }
`;
export const GetOrgPendingAPIInvoiceList = /* GraphQL */ `
  query GetOrgPendingAPIInvoiceList($input: GetOrgPendingAPIInvoiceListInput) {
    GetOrgPendingAPIInvoiceList(input: $input)
  }
`;
export const GetOrgAccMailTriggerList = /* GraphQL */ `
  query GetOrgAccMailTriggerList($input: GetOrgAccMailTriggerListInput) {
    GetOrgAccMailTriggerList(input: $input)
  }
`;
export const GetSESVerifiedIdentities = /* GraphQL */ `
  query GetSESVerifiedIdentities($input: GetSESVerifiedIdentitiesInput) {
    GetSESVerifiedIdentities(input: $input)
  }
`;
export const GetMultiOrgDetails = /* GraphQL */ `
  query GetMultiOrgDetails($input: GetMultiOrgDetailsInput) {
    GetMultiOrgDetails(input: $input)
  }
`;
export const GetInvoiceDueListCollector = /* GraphQL */ `
  query GetInvoiceDueListCollector($input: GetInvoiceDueListCollectorInput) {
    GetInvoiceDueListCollector(input: $input)
  }
`;
export const GetMasterCollectorListByID = /* GraphQL */ `
  query GetMasterCollectorListByID($input: GetMasterCollectorListByIDInput) {
    GetMasterCollectorListByID(input: $input)
  }
`;
export const GetOrgCollectorPaymentStatusList = /* GraphQL */ `
  query GetOrgCollectorPaymentStatusList(
    $input: GetOrgCollectorPaymentStatusListInput
  ) {
    GetOrgCollectorPaymentStatusList(input: $input)
  }
`;
export const GetOrgInvoiceMappingListByID = /* GraphQL */ `
  query GetOrgInvoiceMappingListByID(
    $input: GetOrgInvoiceMappingListByIDInput
  ) {
    GetOrgInvoiceMappingListByID(input: $input)
  }
`;
export const GetOrgInvoiceDetailsByID = /* GraphQL */ `
  query GetOrgInvoiceDetailsByID($input: GetOrgInvoiceDetailsByIDInput) {
    GetOrgInvoiceDetailsByID(input: $input)
  }
`;
export const GetOrgArchivedList = /* GraphQL */ `
  query GetOrgArchivedList($input: GetOrgArchivedListInput) {
    GetOrgArchivedList(input: $input)
  }
`;
export const GetOrgCustRevenueGraph = /* GraphQL */ `
  query GetOrgCustRevenueGraph($input: GetOrgCustRevenueGraphInput) {
    GetOrgCustRevenueGraph(input: $input)
  }
`;
export const GetOrgBLRevenueGraph = /* GraphQL */ `
  query GetOrgBLRevenueGraph($input: GetOrgBLRevenueGraphInput) {
    GetOrgBLRevenueGraph(input: $input)
  }
`;
export const GetOrgCustInvoiceGraph = /* GraphQL */ `
  query GetOrgCustInvoiceGraph($input: GetOrgCustInvoiceGraphInput) {
    GetOrgCustInvoiceGraph(input: $input)
  }
`;
export const GetOrgCustCollectionGraph = /* GraphQL */ `
  query GetOrgCustCollectionGraph($input: GetOrgCustCollectionGraphInput) {
    GetOrgCustCollectionGraph(input: $input)
  }
`;
export const GetInvoiceCancelledInvoiceList = /* GraphQL */ `
  query GetInvoiceCancelledInvoiceList(
    $input: GetInvoiceCancelledInvoiceListInput
  ) {
    GetInvoiceCancelledInvoiceList(input: $input)
  }
`;
