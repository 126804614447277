import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import { Amplify } from 'aws-amplify';

Vue.config.productionTip = false
Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_TV6QKBBmq",
    region: "ap-south-1",
    userPoolWebClientId: "4jalkev24f5ptjjuv2sf9r2s6g",
  },
  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint: 'https://wdp3qyalivhhtgzez6jaanpnra.appsync-api.ap-south-1.amazonaws.com/graphql',
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
}),
  new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
  }).$mount('#app')
