import {
  API,
  graphqlOperation
} from "aws-amplify";
import {
  GetOrgDetialsByID
} from "@/graphql/queries.js";

export const get_details_Org = {
  data: () => ({
    OrgList: [],

  }),
  methods: {
    async get_details_Org() {



      try {
        let result = await API.graphql(
          graphqlOperation(GetOrgDetialsByID, {
            input: {
              org_id: Number(this.$store.getters.Getcurrentdetails.org_id),
            },
          })
        );

        this.OrgList = [];
        var response = JSON.parse(result.data.GetOrgDetialsByID);
        if (response.status == "success") {
          this.OrgList = response.response[0];
          this.$store.commit("SetOrgdetails",  this.OrgList);
        }
        else {
          this.$store.commit("SetOrgdetails",  {});
          this.OrgList = [];
        }


      } catch (error) {
        this.OrgList = [];
      }
    },
  }
}